import './App.css';
import logo from './assets/logoapp.png';
import 'animate.css';
import video from './assets/pexels_videos_2620043 (240p).mp4'

function App() {
  return (
    <div className="App">
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
      </head>
      <video preLoad autoPlay muted loop id="myVideo">
        <source src={video} type="video/mp4"/>
      </video>

      <div className="row">
        <div id='texte' className="item">
          <img id='logo' src={logo} alt="Logo c'encore bon?"/>
          <div id='titre'>C'encore bon?</div>
          <p>
            Vous êtes-vous déjà demandé, en découvrant un produit douteux dans votre réfrigérateur, "C'est encore bon ?" <br/>
            Si la réponse est oui, cette application est spécialement conçue pour vous !
          </p>
          <p>
            "C'encore bon ?" offre la possibilité de scanner vos aliments dès leur ouverture et de vous rappeler leur date de péremption.
            Ainsi, fini les soucis liés à la fraîcheur de vos produits ! 
          </p>
          <p>
            Inquiets à l'idée que le contenu de votre frigo puisse circuler sur internet ? <br/>
            Soyez rassurés, car "C'encore bon ?" ne nécessite aucune inscription et toutes vos données restent confidentielles, stockées uniquement sur votre appareil.
          </p>
          <a className='lien' href='https://play.google.com/store/apps/details?id=com.mc.cencorebongratuit&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target={'_blank'} rel="noreferrer">
            <img id="googlePlay" alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png'/>
          </a>
        </div>
      </div>
    </div>
  );
}



export default App;
